<template>
    <div class="section-top-bar" tabindex="-1">
        <div class="section-top-bar-text">
            <span class="first-line">{{ $t("Home") }} / {{ formattedSelectedItem() }}</span>
            <span class="second-line">{{ subsection !== undefined ? $t(subsection) : $t(selectedItem) }}</span>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    components: {},
    name: "SectionTopBar",
    props: {
        selectedItem: String,
        subsection: String
    },
    data: function () {
        return {
            innerSection: "",
        };
    },
    methods: {
        formattedSelectedItem(): string {
            return this.selectedItem
                ? this.selectedItem
                    .split(/[,/]/)
                    .map(segment => this.$t(segment.trim()))
                    .join(" / ")
                : "";
        },
    },
    mounted: function () { },
    beforeUnmount: function () { },
    watch: {},
});
</script>

<style scoped>
@import "@/style/layout/section-top-bar.css";
</style>
